body{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
}
h1{
  font-size: 2vh;
  margin: 0;
}
.content{
    margin: auto;
    width: 693px;
    border: 3px solid var(--post-border-color);
    border-top: none;
}
.mediaOnly{
  /* display: grid; */
  /* overflow-x: hidden; */
}
@media only screen and (min-width: 1000px) {
  .content{
    margin: auto;
    width: 693px;
}
}
@media only screen and (max-width: 1000px) {
  .content{
    width: 100%;
    border: none;
}
}