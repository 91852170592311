.postSection {
  border-bottom: 3px solid var(--post-border-color);
  padding: 1vh 0 1vh 0;
}
.content {
  position: relative;
  width: 100%;
}

.imgStandard {
  max-width: 100%;
  max-height: 55vh;
  margin: auto;
  display: block;
  object-fit: contain;
}

.text {
  padding: 0 0 0 2%;
  display: grid;
}
.banner {
  display: grid;
}
.imgArea {
  background-color: rgba(0, 0, 0, 0.1);
}
/* The Modal (background) */
.imageZoomedBG {
  /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 1); /* Black w/ opacity */
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.imageZoomed {
  margin: auto;
  display: block;
  max-height: 100%;
  animation: zoom 0.4s;
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.date {
  position: absolute;
  top: 27px;
  color: #f1f1f1;
  font-size: 26px;
  font-weight: bold;
  transition: 0.3s;
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@media only screen and (max-width: 1000px) {
  .imgStandard {
    height: 70vw;
  }
  .imageZoomed {
    max-width: 100%;
  }
}
