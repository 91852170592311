.imgStandard {
  width: 99%;
  height: 99%;
  object-fit: cover;
}

.imgArea {
  width: 33.33%;
  height: 350px;
  float: left;
  margin: 0;
  padding: 0;
  display: inline-flex;
  /* padding: 1px 0 1px 0; */
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@media only screen and (max-width: 1000px) {
  .imgArea{
    height: 200px;
  }
}
