.startup{
    position: absolute;
    height: 100%;
    width: 100%;
}
.ddd{
    margin:0;
    display: block;
    animation: pulse 5s linear alternate infinite;
}

.onlydans{
    display: flex;
    align-items: center;
    height: 100%;
    
}

@keyframes pulse {
0% {
 transform: scale(0.2);
}
50% {
transform: scale(0.4);
}
75%{
    transform: scale(0.6);
}
100% {
transform: scale(0.4);
}
}
@media only screen and (max-width: 1000px) {

    @keyframes pulse {
        0% {
         transform: scale(0.6);
        }
        50% {
        transform: scale(0.8);
        }
        75%{
            transform: scale(1);
        }
        100% {
        transform: scale(0.8);
        }
    }
  }