.row{
    padding: 1vh 0 0 0 ;
    width: 100%;
    border-bottom: 3px solid var(--post-border-color);
}
.text_wrapper{
    width: 50%;
    display: inline-flex;
    padding: 1vh 0 1vh 0;
    border: 0px;
    font-weight: 500;
    background: var(--bg-color);
    font-size: 1em;
}
.text{
    text-align: center;
    margin: auto;
    color: var(--text-color);
    font-family: var(--font-family);
}
.text_wrapper:hover{
    background: var(--post-border-color);
}
.active{
    width: 33%;
    display: inline-flex;
    padding: 1vh 0 1vh 0;
    border: 0px;
    border-bottom: 3px solid black;
    font-weight: 500;
    background: white;
    font-size: 1em;
}