.name{
    color: var(--text-color);
    font-size: 2vh;
    margin: 0;
}
.username{
    color: #8B96A2;
    font-size: 1.5vh;
    margin: 0;
}
.userText{
    margin: 0;
    padding: 1vh 0 1vh 0;
    position: relative;
    display: inline-block;
    top: 0;
    
}
.userSection{
    padding: 0 0 0 2%;
}
.profilePic{
    width: 100px;
    border-radius: 100%;
    position: relative;
    border: 3px solid var(--bg-color);
    background: var(--bg-color);
}
.profilePic:hover{
    animation: spin 2s;
}
.bannerImg{
    width: 100%;
    height: 130%;
    background-size: 100%;
    position: absolute;
    background-image: var(--banner-location);
}

.banner{
    color: white;
    width:100%;
    height: 100%;
    padding: 0 0 15% 0;
    position: relative;
}
.topName{
    display:block;
    color: white;
    font-size: 2vh;
}
.underTopName{
    display:block;
    color: white;
    font-size: 1.5vh;
}
.stickyName{
    position: sticky;
    align-content: center;
    align-items: center;
    top:0;
    position: -webkit-sticky;
}
.backButton{
    color: white;
    position: ab;
    padding: 0 0 0 2%;
    align-content: center;
    align-items: center;
    font-size: 3vh;
}
.themeButton{
    color: white;
    font-size: 3vh;
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px 2% 0 0;
}
.textArea{
    color: white;
    display: inline-block;
    position: relative;
    align-content: center;
    align-items: center;
    padding-left: 2%;
}
@keyframes spin {
    0% {
        transform:rotate(0deg);
    }
    100% {
        transform:rotate(360deg);
    }
}